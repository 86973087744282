/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/variables.override';
@import '../../../../../../src/style/abstract/media.override';
@import '../../../../../../src/style/abstract/button.override';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable indentation */
/* stylelint-disable csstools/use-logical */
.FlexibleFormsWidget {
    margin: 100px 0;

    h2 {
        margin-bottom: 50px ;
    }

    .Field-Wrapper {
        margin: 40px 0;
        .Field_type_image, .Field_type_file {
            input {
                width: 100%;
            }
        }
   }
   .FieldGroup-Wrapper {
      margin: 30px 0;
      .FieldGroup {
          display: flex;
          .Field-Wrapper {
              margin: 0 30px 0 0;
          }
      }
   }
   .FlexibleForms-Button {
       margin: 50px 0;
       width: 30%;
   }

   .Field {
        &-CheckboxLabel {
            input[type="checkbox"] {
                z-index: 1;
            }
        }

        &-Link {
            z-index: 2;
            font-size: 16px;

            a {
                font-size: 16px;
                font-weight: 700;
            }
        }
   }
}
